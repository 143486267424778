<template>
    <div class="box my-account">
        <div class="textOk">
            <div class="textOk_icon">
                <i class="el-icon-success"></i>
            </div>
            <div class="textOk_text">
                订单已提交
            </div>
            <div class="textOk_see">
                <el-button type="primary" size="mini" @click="seeOrder">查看订单</el-button>
            </div>
        </div>
        <div class="mt30">
            <div class="panel">
                对公转账必填信息
            </div>
            <div class="panel">
                <pre>
                  开户名称：{{ $store.getters.accountInfo.value.bank_account_name }}
                  开户银行：{{ $store.getters.accountInfo.value.bank_name }}
                  账号：{{ $store.getters.accountInfo.value.bank_account_no }}
                </pre>
            </div>
        </div>
        <div class="buyCouponPay">
            <el-form :model="shopCouponsData" label-width="120px" :rules="rules" ref="shopCouponsData">
                <el-form-item label="订单号：" v-if="query.order_no">
                    {{ query.order_no }}
                </el-form-item>
                <el-form-item label="交易流水号：" v-if="query.out_trade_no">
                    {{ query.out_trade_no }}
                </el-form-item>
                <el-form-item label="应付金额：" style="color: red;font-weight: bold;">
                    {{ query.total_price }} 元
                </el-form-item>
                <el-form-item label="支付凭证：" prop="pay_prove">
                    <el-upload :action="uploadActionUrl" list-type="picture-card" :on-preview="handlePictureCardPreview"
                        :limit="5" :on-success="handleAvatarSuccess" :on-exceed="handleExceed" :file-list="fileList"
                        :before-upload="beforeAvatarUpload" :on-remove="handleRemove">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog append-to-body :visible.sync="showImg">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </el-form-item>
                <el-form-item label="备注：">
                    <el-input type="textarea" maxlength="300" v-model="shopCouponsData.remarks"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" @click="subBuyData('shopCouponsData')">提交申请</el-button>
                </el-form-item>
            </el-form>
        </div>

    </div>
</template>

<script>

import Config from "@/utils/config"
import { offlineUploadCertificate } from "@/api/order/order";
export default {
    name: "",
    data() {
        return {
            dialogImageUrl: "",
            showImg: false,
            uploadActionUrl: Config.baseUrl + "/api/upload/headimg",// 图片上传地址
            shopCouponsData: {
                order_no: "",
                out_trade_no: "",
                pay_prove: [],// 支付凭证
                remarks: ""// 用户备注
            },
            fileList: [],
            query: {},
            rules: {
                pay_prove: [
                    { required: true, message: "请上传支付凭证", trigger: "blur" }
                ]
            }
        }
    },
    props: {},
    mixins: [],
    watch: {},
    computed: {},
    created() {
        this.$store.dispatch("member/sysBankAccountDat", this.$route.query)
        this.query = this.$route.query
    },
    mounted() {
    },
    methods: {
        seeOrder() {
            this.$router.push({
                path: "/member/order_list"
            })
        },
        subBuyData(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.voucherOrderPayData()
                } else {
                    return false
                }
            })
        },
        // 提交支付凭证
        voucherOrderPayData() {
            var loading = this.$loading({
                lock: true,
                text: "提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            })
            let par = {
                out_trade_no: this.query.out_trade_no,
                order_no: this.query.order_no,
                pay_imgs: this.shopCouponsData.pay_prove,
                rnt: Math.random(),
                remarks: this.shopCouponsData.remarks
            }
            offlineUploadCertificate(par).then(res => {
                loading.close()
                this.$router.push({
                    path: '/orderPaySuccess'
                })
            })
        },
        handleRemove(file, fileList) {
            let list = []
            fileList.map(item => {
                if (item.response) {
                    list.push(item.response.data.pic_path)
                } else {
                    list.push(item.url)
                }
            })
            this.shopCouponsData.pay_prove = list
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg" || "image/png"
            const isLt2M = file.size / 1024 / 1024 < 10

            if (!isJPG) {
                this.$message.error("上传图片只能是 JPG ,png格式!")
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 10MB!")
            }
            return isJPG && isLt2M
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 5 个文件!`)
        },
        handleAvatarSuccess(res, file) {
            let data = res.data
            this.shopCouponsData.pay_prove.push(data.pic_path)
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.showImg = true
        }
    }
}
</script>

<style scoped type="text/scss" lang="scss">
.my-account {
    background: #ffffff;
    padding: 50px 20px;
    font-size: 16px;
}

.el-form-item {
    margin-bottom: 10px;
}

.textOk {
    display: flex;
    align-items: center;

    &_icon {
        color: #4caf50;
        font-size: 60px;
        padding-left: 30px;
    }

    &_text {
        font-size: 26px;
        font-weight: 700;
        color: #54b225;
        margin: 0 10px;
    }

    &_see {
        margin-left: 30px;
    }
}

.buyCouponPay {
    width: 550px;

    ::v-deep .el-upload--picture-card {
        width: 100px;
        height: 100px;
        line-height: 98px;
    }

    ::v-deep .el-upload-list--picture-card .el-upload-list__item {
        width: 100px;
        height: 100px;
    }
}
</style>
